/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//color gris: #B7C1C9
//color blau: #314B87
@font-face {   
    font-family: 'works';    //This is what we are going to call
    src: url('assets/fonts/WorkSans-Regular.ttf');
  }
ion-content::part(scroll) {
    touch-action: pan-y pinch-zoom;
    background-color: #fff !important;
  }
:root {background-color: #fff !important;}
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
ion-toolbar::part(scroll) { background-color: #fff !important;}
ion-button {font-weight: 800;}
.fini {margin-bottom: 60px;}
.pendientes .alert-wrapper {
  background: #ac0404;
  color:white;
  h2 {color:white;}
  div {color:rgb(155, 148, 148);}
}
.action-sheet-group {
  overflow: auto !important;
  -webkit-overflow-scrolling: touch;
}
.btnpendientes {
  color:var(--ion-color-success);
}
button.alert-button.btnpendientes {
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
  padding: 8px;
  text-align:"center";
  width:50%;
  span {
    justify-content: center;
    -ms-flex-pack: center;
}
.alert-button-inner.sc-ion-alert-md {
  -ms-flex-pack: center;
  /* justify-content: flex-end; */
}
}
button.alert-button.btncancel {
  background-color: var(--ion-color-light);
  color: var(--ion-color-warning-contrast);
  opacity:0.6;
  size:"small";
}
// ion-header { background-color: #000 !important;}
// ion-footer { background-color: #000 !important;}
// ion-content { background-color: #000 !important;}
// ion-toolbar { background-color: #000 !important;}
form.mayusc ion-input {--background:#9eaac0;--color:#132246;text-transform: uppercase;}
// ion-form { background-color: #314B87 !important;}
ion-select { background-color: white !important; color: #314B87; max-width: 90%;}
ion-label {color:#8ea2c5 !important; font-weight: 500 !important; font-family:'works';}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #314B87;
  opacity: 1; /* Firefox */
}
ion-searchbar {text-transform: uppercase;}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #314B87;
}
.upper {text-transform: uppercase;}
::-ms-input-placeholder { /* Microsoft Edge */
  color: #314B87;
}
.fons {background-color: #314B87 !important;}

.logoppal {object-fit: contain; padding-left:25px; padding-right:25px;}
.logoppal * {max-height: 200px !important; height: 100px !important;}
.logoboto {height: 60px; width:60px; margin:auto;}
.logosponsor {height: 60px; width:140px; margin:auto;}

.botohome { padding: 5px 1px; margin-right: 5px; margin-left: 5px; font-family:'works'; font-weight: 300 !important; letter-spacing: 2px;}
.botoapp {background-color: white; color:#000;}

.clickable {cursor:pointer;}
.ocult {display:none;}

//ESTADOS
.ABIERTO {background-color: lightgreen; color:black !important;}
.CERRADO {background-color: black;color:lightcyan !important;}
.PENDIENTE {background-color: rgb(255, 117, 62);color:black !important;}
.URGENTE {background-color: rgb(243, 60, 60);color:black !important;}
.INFORME {background-color: #314B87;color:lightcyan !important;}
.RANGO {background-color: rgb(209, 247, 121); color:black !important;}

//TIPOUSER
.t2 {background-color: lightgreen; color:black !important;}
.t9 {background-color: #314B87;color:lightcyan !important;}
.t7 {background-color: rgb(255, 117, 62);color:black !important;}

.lst_fecha {background-color: rgba(255,255,255,0.2);}
.lst_campo {color:#fff; font-size: 14px; font-weight: 300; text-align: center;}
.lst_mail ion-icon{color:#fff; font-size: 46px; text-align: center;}

.tabla {table-layout:fixed; overflow-x: scroll; overflow-y: scroll; text-align: center;}
.tabla tr {overflow-x: scroll; overflow-y: scroll; text-align: center;}
.tabla td {white-space: nowrap;padding-right: 6px;padding-left: 6px;border:solid 1px black;}
ion-item-divider { --background: rgba(fff,0.2);
  --color: #fff;
  --padding-top: 1px;
  --padding-bottom: 1px;
  --padding-start: 20px;
  --padding-end: 2px;
  font-size:12px;
}
ion-select.selectfull {max-width: 100% !important; width:400px !important;}
.popover-content {max-width: 100% !important; width:400px !important;}
.selectfull ion-label {overflow: visible; white-space: wrap;}

.iconboto {color:#fff}
.iconimgc10 { width: 10px; height: 10px; margin-right: 1px; object-fit: contain;}
.iconimgc20 { width: 25px; height: 25px; margin-right: 1px; object-fit: contain; margin:auto;}
.iconimg { width: 100%; height: 100%; object-fit: contain;}
.iconimg2 { width: 40px; height: 40px; margin-right: 1px; object-fit: contain;}
.iconimg3 { width: 30px; height: 30px; margin-right: 10px; object-fit: contain; background-color: white;}
.iconimg5 { width: 50px; height: 50px; margin-right: 1px; object-fit: contain;}
.iconimg6 { width: 60px; height: 60px; margin-right: 1px; object-fit: contain;}
.iconimg6x5 { width: 60px; height: 50px; margin-right: 1px; object-fit: contain;}
.iconimg8 { width: 80px; height: 80px; margin-right: 1px; object-fit: contain;}
.iconimg10 { width: 100px; height: 100px;  object-fit: contain;}
.iconimg15 { width: 150px; height: 150px; object-fit: contain;}
.obligado { color: #43b4de;}
.wrap {white-space: normal;}
.confirm_borro {--background:darkred; --height:300px; --width:300px; color:white; }
.confirm_borro {
  ion-content {background-color: darkred;}
}
.scroll2 {
  flex-wrap: nowrap;
  overflow: scroll!important;
  //overflow-y: hidden;
}
.scroll3 {
    flex-wrap: nowrap;
    overflow-x: scroll!important;
    overflow-y: hidden;}
.scroll-items {
  //justify-content: flex-start; // changed
  flex-wrap: nowrap;
  overflow: scroll;
  overflow-x: auto;
  //overflow-x: auto !important;
  overflow-y: hidden;
  //white-space: nowrap;
}
.scroll-items ion-col { // added
   // display: flex;
   // flex-wrap: nowrap;
   overflow:hidden;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
.flexr {display: flex-row;}
.dflex {display: flex;}
.iflex {display: inline-table;}
.tablecell {display:table-cell;}
.tablerow {display:table-row;}
.W {background-color:green;}
.L {background-color:red;}
.D {background-color:orange;}

.mt3 {margin-top: 3px;}  
.mt10 {margin-top: 10px;}
  .mb10 {margin-bottom: 10px;}
  .ma {margin: auto;}
  .pt10 {padding-top: 10px;}
  .pb10 {padding-bottom: 10px;}
  .pt30 {padding-top: 30px;}
  .pb30 {padding-bottom: 30px;}
  .banner { width:100%; height: 200px;}
  .pl0 {padding-left: 0px;}
  .pr0 {padding-right: 0px;}
  .pl10 {padding-left: 10px;}
  .pr10 {padding-right: 10px;}
  .ml10 {margin-left: 10px;}
  .mr10 {margin-right: 10px;}
  .mr30 {margin-right: 30px;}
  .pad0 {padding:0;}
  .pad10 {padding:10px;}
.centrarflex button{
    display: flex;    
    align-items: center;    
    justify-content: center;
    text-align: center;     
}
.ion-color.sc-ion-searchbar-md-h .searchbar-input.sc-ion-searchbar-md {border-radius: 15px; padding-top: 10px;padding-bottom: 10px;}
.br25 {border-radius: 30px;}
.br25div > div > input{border-radius: 30px;}
.br15 {border-radius: 15px;}
.br30 {border-radius: 30px;--border-radius: 30px;}
.borderb {border-bottom: 2px solid black; }
.bordert {border-top: 2px solid black; }
.borderw {border:2px solid white;}
.brb {border:2px solid darkblue;}


.blau button{ color:#244b84; }
.blau{color:#244b84;}
.bblau{background-color:#244b84;}
.bblau2{background-color:#3171e0;}
.blanc {color:#fff;}
.bblanc {background-color:#fff;}
.blaufosc{color:#09244d;}
.bblaufosc{background-color:#09244d;}
.bblaufluix {background-color:#43b4de}
.bverd {background-color:#2fd370}
.verd {color:#2fd370}
.bgroc {background-color:#e3bb54 }
.groc {color:#e3bb54;}
.vermell {color: red;}
.bvermell {background-color:darkred; --background:darkred;}




.titolblau {color:#fff; font-size: 16; font-weight: 600; text-align: center;}
.titolblanc {color:#244b84; font-size: 16; font-weight: 600; text-align: center;}
.titolboto {background-color:#43b4de; color:#244b84; font-weight: 600; text-align: center;
            font-size: x-large; padding-top: 10px; padding-bottom: 10px;}
.titolnegre {background-color:white; color:darkblue; font-weight: 600; text-align: center;
  font-size: large; padding-top: 10px; }

// .fonsjor {background-color: #292b2c; border-radius: 20px;}
.fonsjor {background: linear-gradient(#314B87 60%, #B7C1C9 40%); border-radius: 15px;}

.data {color: #314B87;  font-weight: 800;}
.titols {text-align: center;border-radius: 1rem; font-size: x-large; padding-top: 10px; padding-bottom: 10px; background-color: #e3bb54;}
.rodo {
    border-radius: 50%;
    border: 2px solid #f5f5f5;
}

.truncate {
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.wrap {white-space: break-spaces;}
  .caducat {
      background-color: red;
  }
  
  .textcenter {text-align: center;}
  .fsize8 {font-size: 8px;}
  .fsize10 {font-size: 10px;}
  .fsize12 {font-size: 12px;}
  .fsize14 {font-size: 14px;}
  .fsize16 {font-size: 16px;}
  .fsize18 {font-size: 18px;}
  .fsize20 {font-size: 20px;}
  .fsize26 {font-size: 26px;}
  .fsize32 {font-size: 32px;}
  .fsize40 {font-size: 40px;}
  .fsize50 {font-size: 50px;}

  .negrita {font-weight: bold;}
  .scroll {display: inline-block;}
  .flex {width: 100%;overflow-x: scroll;position:relative;white-space: nowrap;}
  .result {color: white;font-size: 2rem; border:2px solid white;}
  .rel {position: relative;}
  .line15 {line-height: 1.5;}
  .mauto {margin: auto;}
.jcc {justify-content: center;}
.jcs {justify-content: space-around;}

.scaled {
  width: 150px;
  height: 150px;
  transform-origin: left;
  transform: scale(1);
}
/* TABLAS */
.taula td,th {border-bottom: 1px solid #8ea2c5; padding: 2px; border-right: 1px solid #8ea2c5;}
.taula {width:100%;}

.checkb > .checkbox-icon {border-color:#43b4de !important; border-width:2px !important;}
/* FORMULARIOS */
form { background-color: rgba(#314B87,0.9) !important; color: #314B87;border-radius: 30px;}
form ion-item{ background-color: #314B87; color: #314B87;border-radius: 15px; margin: 10px;}
form ion-input{ background-color: transparent !important; color: #314B87; }
form ion-select{ background-color: transparent !important; color: #314B87; width: fit-content; max-width: 90%;}
form ion-option{ color: #314B87;}
form ion-radio{ color: #314B87;}
form ion-label{ color: #1a2c4b;}

/* GRADIENTS */
.grad .button-native{
  background: linear-gradient(#a88d16, #e3bb54);
}
.grad2 {
  background: linear-gradient(#a88d16, #e3bb54);
}
.cgrad2 {background: -webkit-linear-gradient(#a88d16, #e3bb54); -webkit-background-clip: text; background-clip: text; -webkit-text-fill-color: transparent;
 fill: linear-gradient(#a88d16, #e3bb54);}